import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "./app";

const reactContainer = document.getElementById("app-container");
const root = createRoot(reactContainer!)
root.render(  
<StrictMode>
    <App />
  </StrictMode>)
